@import "../Styles/mediaquery";
@import "../Styles/variables";

.Utils {
  .Navigation {
    background-color: rgba($color: $secondary, $alpha: 0.8);
    min-width: 200px;
    height: 100%;
    width: fit-content;
    display: grid;
    grid-template-rows: auto 1fr;
    animation: 1000ms slide;

    @keyframes slide {
      from {
        margin-left: -100%;
      }
      to {
        margin-left: 0%;
      }
    }

    .div1 {
      border: none;
      height: 100%;
      overflow-y: auto;

      .NavLink {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;
        padding: 2px 10px 2px 10px;
        cursor: pointer;
        height: 100%;

        i {
          color: white;
          line-height: 24px;
          height: 24px;
        }

        p {
          color: white;
          font-size: small;
        }
      }

      .NavGroup {
        padding: 5px 10px 5px 1em;

        .item {
          color: white;
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 10px;
          cursor: pointer;

          h3 {
            font-size: medium;
            font-weight: 400;
          }
        }
      }
    }

    .div2 {
      margin-top: auto;

      .logout {
        width: fit-content;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;
        color: $accent;
        margin: 5px 1em 5px auto;
        cursor: pointer;

        img {
          height: 24px;
          width: 24px;
        }
      }

      p {
        color: white;
        text-align: center;
        padding: 1em;
        font-size: small;
      }

      a {
        color: $accent;
        text-decoration: none;
      }
    }

    @include maxwidth(mobile) {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10000;
      background-color: rgba($color: $secondary, $alpha: 1);
    }
    @include maxwidth(tablet) {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10000;
      background-color: rgba($color: $secondary, $alpha: 1);
    }
  }
  .hideNav {
    width: fit-content;
    height: fit-content;
    background-color: $primary;
    display: flex;
    align-items: center;
    height: 64px;

    i {
      color: #ffff;
      margin-left: 10px;
      cursor: pointer;
    }
    i:hover {
      color: $accent;
      margin-left: 10px;
      cursor: pointer;
    }
  }



  .select {
    max-width: 200px;
    width: 100%;
    margin: 5px auto 5px auto;

    label {
      display: block;
    }

    select {
      width: 100%;
      outline: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #60606040;
      padding: 8px;
      background-color: transparent;
      color: $secondary;
    }
  }

  .selector {
    background-color: $bg;
    padding: 1em 0 1em 0;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: auto;

    .thisSelector {
      display: block;
      margin: auto;
      width: 25%;
      border: none;
      outline: none;
      background: $bg;
      color: $secondary;
    }
  }

  .input {
    max-width: 200px;
    width: 100%;
    margin: 5px auto 5px auto;

    label {
      display: block;
    }

    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input {
      width: 100%;
      outline: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #60606040;
      padding: 8px;
      background-color: transparent;
      color: $secondary;
    }
  }

  .descriptionBox {
    background-color: white;
    //border: 1px solid black;
    border-radius: 10px;
    width: 70%;
    margin: auto;
    text-align: center;
    box-shadow: 1px 1px 5px #60606030;
    border-radius: 5px;

    .text {
      width: 90%;
      margin: auto;
      color: $secondary;
      padding-bottom: 0.4em;
      padding-top: 0.3em;
      h6 {
        font-size: medium;
        margin-bottom: 0.3em;
      }
    }
  }
}

.buttonRegister {
  max-width: 300px;
  width: 30%;
  margin: 10px auto 10px auto;

  button {
    border: none;
    background-color: $primary;
    color: white;
    padding: 8px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    margin: 1em auto;
  }

  button:hover {
    font-weight: 700;
    color: $primary;
    background-color: white;
    border: 1px solid $primary;
  }
}

button {
  margin: 6px;
}
