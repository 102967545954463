@import "./mediaquery";
@import "./variables.scss";

.reports {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;

  .bar {
    background-color: white;
    border-radius: 5px 5px 0 0;
    box-shadow: 1px 1px 5px #60606030;
    margin-bottom: 1px;
    z-index: 99;
    .container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: fit-content;

      h4 {
        cursor: pointer;
        padding: 10px 1em;
        font-weight: 520;
        font-size: small;
        color: $primary;
        text-align: center;
      }
      h4:hover {
        color: $primarylight;
        background-color: #feece2;
        box-shadow: 1px 1px 5px #60606030;
      }
      .active {
        color: $primarylight;
        background-color: #feece2;
        box-shadow: 1px 1px 5px #60606030;
      }
    }
  }

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    position: relative;

    h3 {
      font-size: small;
      font-weight: 520;
      color: $primarylight;
    }
    hr {
      color: #60606030;
      margin-bottom: 1em;
      margin-top: 10px;
    }

    .options {
      width: fit-content;
      margin: 0 0 0 auto;
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;

      .usrselect {
        margin: 0px 0 10px 0;

        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        select {
          margin: 0px 0 0 0;
          padding: 5px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }

    .dow {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 10px;
      margin: 10px 0 10px 0;

      h4 {
        background-color: #ffebfe;
        text-align: center;
        padding: 5px;
        font-size: small;
        font-weight: 600;
        color: $primary;
      }
      @include maxwidth(mobile) {
        display: none !important;
      }
      @include maxwidth(tablet) {
        display: none !important;
      }
    }

    .calendar {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 10px;
      margin: 10px 0 10px 0;

      .day {
        min-height: 100px;
        background-color: #ffebfe;
        border-radius: 4px;
        padding: 10px;
        box-shadow: 1px 1px 2px #60606030;
        border-bottom: 3px solid $secondary;
        cursor: pointer;
        position: relative;

        .chart {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }

        i {
          font-size: small;
          color: $secondary;
          padding-right: 5px;
        }

        h6 {
          font-size: xx-small;
          color: gray;
          font-weight: 400;
          text-align: right;
        }

        h3 {
          font-size: large;
          color: $primary;
        }

        p {
          font-size: small;
          color: $secondary;
        }
      }
      .day:hover {
        box-shadow: 1px 1px 5px $secondary;
      }

      @include maxwidth(mobile) {
        grid-template-columns: repeat(2, 1fr) !important;

        .mob {
          display: none !important;
        }

        .chart {
          right: 0px;
          bottom: 0px;
        }
      }
      @include maxwidth(tablet) {
        grid-template-columns: repeat(4, 1fr) !important;

        .mob {
          display: none !important;
        }
      }
    }

    .popup {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      display: flex;
      align-items: center;
      z-index: 999999;
      animation: zoom 1s ease;

      .container {
        background-color: white;
        border-radius: 8px;
        padding: 1em 2em 1em 2em;
        margin: 0 auto 0 auto;
        color: #000000;
        max-width: 70vw;
        max-height: 90vh;
        min-width: 50%;
        height: fit-content;
        overflow-y: auto;
        width: fit-content;
        position: relative;

        .rbar {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          border-radius: 5px;
          overflow: hidden;
          border-bottom: 1px solid #60606030;
          // background-color: #fff8d6;
          margin-bottom: 10px;

          p {
            padding: 5px;
            font-size: small;
            text-align: center;
            cursor: pointer;
          }

          .active {
            background-color: #f7e1ae;
          }
        }

        .details {
          box-shadow: 1px 1px 2px #60606030;
          border-radius: 5px;
          padding: 10px;
          border: 1px solid #60606030;
          h4 {
            font-size: small;
            text-align: center;
            color: $primary;
          }

          p {
            font-size: x-small;
          }
          .section {
            margin-bottom: 10px;
            border-bottom: 1px solid #60606030;
          }
        }

        .wcontent {
          position: relative;
          min-height: 30vh;

          .witem {
            padding: 5px 10px 5px 10px;
            box-shadow: 1px 1px 5px #60606030;
            border-radius: 5px;
            border: 1px solid #60606030;
            margin-bottom: 5px;
            cursor: pointer;

            h4 {
              font-size: small;
              text-align: center;
              color: $primary;
            }

            p {
              font-size: x-small;
            }
          }

          .witem:hover {
            background-color: white !important;
          }

          .tally {
            position: absolute;
            top: 5px;
            right: 0;

            .active {
              background-color: #74465c;
            }

            p {
              background-color: $accent;
              border-radius: 2px;
              cursor: pointer;
              width: 32px;
              height: 32px;
              text-align: center;
              color: white;
            }
            p:hover {
              background-color: #74465c;
            }
          }
        }

        h3 {
          text-align: center;
          font-size: x-large !important;
        }

        .fa-times {
          position: absolute;
          right: 1em;
          top: 10px;
          padding: 10px;
          cursor: pointer;
          color: $primary;
        }
        .fa-times:hover {
          color: $accent;
        }

        hr {
          margin-bottom: 10px;
        }

        i {
          display: block;
          width: fit-content;
          padding: 5px;
          margin: 0 0 0 auto;
          color: $accent;
        }
        i:hover {
          color: $primary;
        }

        h3 {
          padding: 0px 0 10px 0;
          font-size: medium;
        }

        form {
          textarea {
            resize: none;
            width: 100%;
            margin: auto;
            padding: 4px;
            font-size: 18px;
          }
        }

        .input-map {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }
          textarea {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          textarea:focus {
            border-bottom: 2px solid $primary;
          }
          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
        .usrselect {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }

          select {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        p {
          font-size: medium;
          margin: 0px 0 5px 0;

          b {
            color: #74465c;
            cursor: pointer;
          }
        }

        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 12px;
          width: 80%;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 2px solid #60606070;
          border-top: 2px solid #60606070;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
      }
      @include maxwidth(mobile) {
        .container {
          max-width: 85% !important;
        }
      }
      @include maxwidth(tablet) {
        .container {
          max-width: 85% !important;
        }
      }

      .dets {
        display: grid;
        grid-template-columns: 1fr auto;

        .fa {
          color: $accent;
          height: fit-content;
          margin: auto;
          padding: 5px;
        }
        .fa:hover {
          color: $primary;
        }
      }

      .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
      }

      hr {
        margin-bottom: 10px;
      }
    }

    .new {
      h6 {
        color: orangered;
        text-align: center;
        font-weight: 400;
        font-size: medium;
      }
      .input-container,
      .select {
        margin-top: 10px;
      }

      .search {
        position: relative;

        .search_list {
          position: absolute;
          left: 0;
          right: 0;
          top: 54px;
          padding: 10px;
          border: 1px solid #60606030;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606030;
          z-index: 1;
          background-color: white;

          p {
            font-size: small;
            padding: 5px;
            cursor: pointer;
            margin-bottom: 3px;
          }
          p:hover {
            color: $secondary;
          }
        }
      }

      .item {
        box-shadow: 1px 1px 5px #60606030;
        background-color: white;
        border-radius: 5px;
        width: 100%;
        position: relative;
        padding: 10px;
        margin-top: 0;

        h3,
        p {
          font-size: small;
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }

      button {
        display: block;
        margin: 1em auto 1em auto;
        padding: 12px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid #60606070;
        border-top: 2px solid #60606070;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }
      .usrinput {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        textarea {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        textarea:focus {
          border-bottom: 2px solid $primary;
        }

        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
      .usrselect {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        select {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }

    .map {
      background-color: white;
      width: 100%;
      height: 78vh;
      margin-bottom: 1em;
      position: relative;

      .top {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 10px;
        margin-bottom: 10px;

        .search {
          width: fit-content;
          position: relative;
          height: fit-content;

          i {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 8px;
            color: $primarylight;
            display: flex;
            align-items: center;
          }
        }

        input {
          padding: 4px 3em 4px 8px;
          background-color: #feece2;
          border-radius: 5px;
          outline: none;
          border: 1px solid #60606030;
          color: $primary;
          width: 100%;
        }
      }

      .map-element {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        box-shadow: 1px 1px 5px #60606030;
        overflow: hidden;
        border: 2px solid #60606030;
        position: relative;
      }
      .cpopup {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        align-items: center;
        z-index: 999999;
        animation: zoom 1s ease;

        .container {
          background-color: white;
          border-radius: 8px;
          padding: 1em;
          margin: 0 auto 0 auto;
          color: #000000;
          max-width: 70vw;
          max-height: 90vh;
          min-width: 50%;
          height: fit-content;
          overflow-y: auto;
          width: fit-content;
          position: relative;

          h3 {
            text-align: left;
            font-size: large;
          }

          .fa-times {
            position: absolute;
            right: 1em;
            top: 10px;
            padding: 10px;
            cursor: pointer;
            color: $primary;
          }
          .fa-times:hover {
            color: $accent;
          }

          hr {
            margin-bottom: 5px;
            color: #60606030;
          }

          i {
            display: block;
            width: fit-content;
            padding: 5px;
            margin: 0 0 0 auto;
            color: $accent;
          }
          i:hover {
            color: $primary;
          }

          h4 {
            color: $primarylight;
            font-size: small;
            font-weight: 500;
          }

          h3 {
            padding: 0px 0 10px 0;
            font-size: medium;
          }

          p {
            font-size: small !important;
            margin: 0px 0 5px 0;
            b {
              color: $primary;
              font-weight: 520;
            }
          }
        }
        @include maxwidth(mobile) {
          .container {
            max-width: 85% !important;
          }
        }
        @include maxwidth(tablet) {
          .container {
            max-width: 85% !important;
          }
        }

        .dets {
          display: grid;
          grid-template-columns: 1fr auto;

          .fa {
            color: $accent;
            height: fit-content;
            margin: auto;
            padding: 5px;
          }
          .fa:hover {
            color: $primary;
          }
        }

        .content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 5px;
        }

        hr {
          margin-bottom: 10px;
        }
      }
    }

    .myreports {
      .summary{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1em;
        margin-bottom: 1em;

        .box{
          width: 100%;
          height: 100%;
          background-color: white;
          box-shadow: 1px 1px 3px rgba(96, 96, 96, 0.1882352941);
          padding: 10px;

          .single{
            display: grid;
            grid-template-columns: auto 1fr;
            place-content: center;
            gap: 10px;
            margin: auto;
            .ts{
              width: 44px;
              height: 44px;
              color: #ffa726;
              display: block;
              margin: auto;
            }
            h4{
              font-size: x-large;
              font-weight: 900;
              color: #028e37;
              text-align: right;
              margin-bottom: 4px;
            }
            p{
              font-size: small;
              color: gray;
              text-align: right;
            }
          }
        }
        
      }
      .top {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 10px;
        margin-bottom: 10px;

        .search {
          width: fit-content;
          position: relative;
          height: fit-content;

          i {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 8px;
            color: $primarylight;
            display: flex;
            align-items: center;
          }
        }

        input {
          padding: 4px 3em 4px 8px;
          background-color: #feece2;
          border-radius: 5px;
          outline: none;
          border: 1px solid #60606030;
          color: $primary;
          width: 100%;
        }
      }

      .my_list {
        // min-height: 60vh;
        h4{
          color: $primarylight;
        }
        p{
          font-size: small;
        }
        .no-data{
          text-align: center;
        }
      }

      .item {
        margin: 3px 0;
        padding: 8px;
        background-color: white;
        border: 1px solid #60606030;
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        cursor: pointer;
        display: grid;
        grid-template-columns: auto 1fr 1fr 1fr 1fr auto;
        align-items: center;
        gap: 1em;
        width: 100%;
        overflow-x: auto;

        h6 {
          padding: 10px;
          background-color: #feece2;
          border-radius: 5px;
          color: $primarylight;
          font-weight: 500;
          font-size: medium;
        }

        h4 {
          color: $primarylight;
          font-weight: 520;
        }

        p {
          color: $primary;
          font-size: small;
          margin: 4px 0;
        }
      }
      .item:hover {
        box-shadow: 2px 2px 10px #60606070;
      }
    }

    @include maxwidth(mobile) {
      .top {
        grid-template-columns: 1fr !important;
      }
      .div3equal{
        grid-template-columns: 1fr;
      }
    }
  }
}
