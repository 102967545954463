@import "./variables";
@import "./mediaquery";

/* Login styles */
.login {
  height: 100vh;
  width: 100vw;
  background-color: #c9d6ff;
  background: url("../assets/imgs/lg1.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* Container styles */
  .container {
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 768px;
    min-height: 480px;
    background-color: white;

    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.3px;
      margin: 20px 0;
    }

    /* Span styles */
    span {
      font-size: 12px;
    }

    /* Anchor styles */
    a {
      color: #333;
      font-size: 13px;
      text-decoration: none;
      margin: 15px 0 10px;
    }

    h6 {
      font-size: small;
      font-weight: 400;
      height: 24px;
      line-height: 24px;
      color: $accent !important;
    }

    /* Button styles */
    button {
      background-color: $primary;
      color: white;
      font-size: 12px;
      padding: 10px 45px;
      border: 1px solid transparent;
      border-radius: 8px;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-top: 10px;
      cursor: pointer;

      /* Hidden button styles */
      &.hidden {
        background-color: white;
        border-color: white;
      }
    }

    div {
      transition: opacity 0.5s ease-in-out;
    }

    /* Form styles */
    form {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 40px;
      height: 100%;

      // /* Input styles */
      // input {
      //   background-color: #eee;
      //   border: none;
      //   margin: 8px 0;
      //   padding: 10px 15px;
      //   font-size: 13px;
      //   border-radius: 8px;
      //   width: 100%;
      //   outline: none;
      // }

    }

    .sign-in {
      position: absolute;
      top: 0;
      left: 100%;
      width: 50%;
      height: 100%;
      transition: left 1s ease-in-out;

      h1 {
        color: $primarylight;
        margin-bottom: 1em;
      }
    }

    .right-panel {
      position: absolute;
      top: 0;
      left: -100%;
      width: 50%;
      height: 100%;
      transition: left 1s ease-in-out;
      background-color: $primary;
      background: linear-gradient(to right, $primary, $primarylight);
      color: white;
      padding: 1em 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px 0 0 50px;

      p {
        margin: 1em 0 3em 0;
      }

      button {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        cursor: pointer;
        display: block;
        margin: auto;
      }
    }

    .reset {
      position: absolute;
      top: 0;
      left: -100%;
      width: 50%;
      height: 100%;
      transition: left 1s ease-in-out;

      h1 {
        color: $primarylight;
        margin-bottom: 1em;
      }
    }

    .left-panel {
      position: absolute;
      top: 0;
      left: 100%;
      width: 50%;
      height: 100%;
      transition: left 1s ease-in-out;
      background-color: $primary;
      background: linear-gradient(to right, $primary, $primarylight);
      color: white;
      padding: 1em 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 50px 50px 0;

      p {
        margin: 1em 0 3em 0;
      }

      button {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 5px;
        cursor: pointer;
        display: block;
        margin: auto;
      }
    }
  }
}