@import "./variables";
@import "./mediaquery";

.users {
  height: 100%;
  width: 100%;

  .user-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .list-items{
    margin: 0 1rem 1rem 0;
  }

 

  @include maxwidth(mobile) {
    .list {
      .div31 {
        grid-template-columns: 1fr;
        .user-list {
          grid-template-columns: 1fr;
        }
      }
      .utp {
        grid-template-columns: 1fr;
      }
    }
  }
  @include maxwidth(tablet) {
    .list {
      .div31 {
        grid-template-columns: 2fr 1fr;
        .user-list {
          grid-template-columns: 1fr;
        }
      }
      .utp {
        grid-template-columns: 1fr;
      }
    }
  }
}
