@import "./variables";

.landing {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;
  background-color: $bg;

  &__heading {
    margin-bottom: 1rem;

    &__text {
      font-size: 1.5em;
      font-weight: 600;
    }

    &--icon {
      font-size: 1.5em;
      color: $accent;
      cursor: pointer;
      background-color: white;
      border-radius: 5px;
    }

    &--icon:hover {
      color: $primary;
    }
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;

    &--bar {
      width: 2%;
      background-color: $accent;
      margin-right: 1rem;
    }
  }

//  &__top {
//   margin-bottom: 1rem;

//   .divdetails {
//     &__grid {
//       display: flex;
//       align-items: stretch;
//       justify-content: space-between;

//       @media (max-width: 768px) {
//         flex-direction: column;
//         gap: 1rem;
//       }

//       @media (min-width: 768px) {
//         flex-direction: row;
//         gap: 2rem;
//       }

//       &--left {
//         display: grid;
//         grid-template-rows: 1fr 1fr;

//         .icon {
//           font-size: 3rem;
//           color: $accent;
//           border-radius: 5px;
//           cursor: pointer;
//           margin-top: 4rem;
//         }

//         .details {
//           margin: 1rem 0;
//         }
//       }

//       &--right {
//         background-color: white;
//         padding: 0 0.6rem;
//       }
//     }
//   }

//   .divchart {
//     @media (max-width: 768px) {
//       margin-top: 2rem;
//     }
//   }
// }

}
